import React from 'react';
import image from '../../assets/images/fordgridPoster.svg'
import { TbChevronRight } from 'react-icons/tb';
import scheduleIcon from '../../assets/images/scheduleIcon.svg'
import uploadIcon from '../../assets/images/uploadIcon.svg'
import fordIcon from '../../assets/images/fordLogo.svg'
import { DStyles } from './dashboardStyles';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate()
  return (
  <div className="grid col-12">
    <div className="col-7">
        <DStyles.PageHeader className='text-3xl ml-2'>FORD GRID CONNECT</DStyles.PageHeader>
        <DStyles.TitleContent className="p-card-content">
          <p className="m-0 text-sm p-0">
            Platform for integrating Utilities (e.g. DTE, DUKE) with Ford Pro and Model-e for optimized charging and data sharing
          </p>
        </DStyles.TitleContent>
        <img src={image} className='col-12 m-0 h-10rem p-0' alt='fordGridPoster'/>
        <DStyles.PageHeader2 className='text-lg font-bold'>USE CASES</DStyles.PageHeader2>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Demand Response - Load Reduction</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Dynamic Price Managed Charging - Load Shifting</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Automated ToU - Load Avoidance</span></DStyles.TitleContent>

        <DStyles.PageHeader2 className='text-lg font-bold'>PROTOCOLS</DStyles.PageHeader2>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>OpenADR 2.0b</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>IEEE 2030.5*</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>ISO 15118-20*</span></DStyles.TitleContent> 
    </div>
    <div className="col-5">
    <DStyles.ButtonContainer className='mt-2'>
                            <DStyles.CustomButton data-testid="generate-btn" className='mr-4' onClick={()=> navigate('/events')}><img src={scheduleIcon} alt="generateIcon" className="mr-2"/>Demand Response</DStyles.CustomButton>
                            <DStyles.CustomButton data-testid="generate-btn"><img src={uploadIcon} alt="generateIcon" className="mr-2"/>Upload Variable Tariff</DStyles.CustomButton>
                        </DStyles.ButtonContainer>
                        <DStyles.PageHeader2 className='text-lg font-bold'>EV DRIVER / FLEET</DStyles.PageHeader2>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Enroll EV Drivers, manage charging schedules</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Earn incentive from utility</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>No impact to daily mobility needs</span></DStyles.TitleContent>

        <DStyles.PageHeader2 className='text-lg font-bold'>UTILITY AGGREGATORS</DStyles.PageHeader2>
        {/* <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Get data and energy services for load management, resource planning, renewable integration. </span></DStyles.TitleContent> */}
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Single interface for utilities to gain access to Ford's EVs as grid resources and get data</span></DStyles.TitleContent>

        <DStyles.PageHeader2 className='text-lg font-bold'>FEATURES</DStyles.PageHeader2>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Generate DR via API (External) & Email</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Consume DR events via UI </span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>User & Authz Management via UI console</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>DR Workflow: Schedule, Generate, Approve, Publish</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>DR Reports to Utility</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Multi-VTN to Single Ford VEN</span></DStyles.TitleContent>
     <DStyles.Container2>
    <div className='text-xs'>* - Future Capabilities.
    <img src={fordIcon} alt='fordIcon' className='ml-3'/></div>
</DStyles.Container2>
    </div>
  </div>
  )}

export default Dashboard;