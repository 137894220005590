import React, { useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { TbInfoCircle } from 'react-icons/tb';
import { EOverlayStyles } from './EventsOverlayStyles';

export const ResponseOverlay = ({ parsedResponse }:any) => {
  const op = useRef<any>(null);
  const handleMouseMove = () => {
    if (op.current) {
      op.current.hide();
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("scroll", handleMouseMove, true);
    }
  };
  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    op.current.toggle(e);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("scroll", handleMouseMove, true);
  };

  const flattenData = (data: string, maxDepth = 3) => {
    const result: any = [];
  
    const processValue = (key: any, value: any, depth: number) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          flatten(item, `${key}[${index}]:`, depth + 1);
        });
      } else if (typeof value === 'object' && value !== null && depth < maxDepth) {
        flatten(value, key, depth);
      } else {
        const shortKey = key.split('.').pop().split('[').shift();
        result.push({ key: shortKey, value: value.toString() });
      }
    };
  
    const flatten = (obj: any, path = '', depth = 0) => {
      Object.entries(obj).forEach(([key, value]: any) => {
        const newPath = path ? `${path}.${key}:` : key;
        processValue(newPath, value, depth);
      });
    };
  
    flatten(data);
    return result;
  };
  

  const renderContent = (data:any) => {
    const flattenedData = flattenData(data);
  
    return (
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
       <tbody>
       {flattenedData.map((item:any) => (
          <tr key={item.id}>
            <EOverlayStyles.KeyCellStyle>{item.key}</EOverlayStyles.KeyCellStyle>
            <EOverlayStyles.CellStyle>{item.value}</EOverlayStyles.CellStyle>
            </tr>
        ))}
      </tbody>
      </table>
    );
  };

  return (

    <>
     <OverlayPanel ref={op} className='text-sm fadein animation-duration-100'>
        {parsedResponse ? renderContent(parsedResponse) : "NULL"}
      </OverlayPanel>
      <Button rounded text icon={<TbInfoCircle />} onClick={handleButtonClick} style={{height:"0.5rem"}}/>
    </>
  )
};

export const ResponseObjectTemplate = (rowData:any) => {
  
  const parseResponseObject = (responseObject:any) => {
    if (!Array.isArray(responseObject)) {
        return null;
    }

    if (!responseObject[1]) {
        return responseObject;
    }

    if (responseObject[0] === "Not Found") {
        return "Not Found";
    }

    try {
        return JSON.parse(responseObject[1]);
    } catch (error) {
        return null;
    }
};
  
const parsedResponse = parseResponseObject(rowData?.response_object);
    
if (!parsedResponse) {
    const responseText = rowData?.response_object ? rowData?.response_object[0] || 'NULL' : 'NULL';
    return <span>{responseText}</span>;
}

return (
  <div style={{ display: "flex", alignItems: "center" }}>
      {rowData?.response_object[0]?.toLowerCase() || 'NULL'}
      {parsedResponse ? <ResponseOverlay parsedResponse={parsedResponse} /> : ""}
  </div>
);
};
export default ResponseObjectTemplate