import React, {useState, useRef, MouseEventHandler, FunctionComponent, useEffect, SetStateAction, Dispatch} from "react";
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import {Toast} from "primereact/toast";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {useNavigate} from "react-router-dom";
import { showToast } from "../../../Utils/Utility";
import { EventPayload, signalNames, vens } from "../../events/EventsForm/eventsFormData";
import { EStyles } from "../../events/EventsForm/eventsformStyles";
import { UpdateScheduleService } from "../../../service/eventScheduleService";
import { RadioButton } from "primereact/radiobutton";
import { Payload, signalPayloadValues } from "../../../types/signalValues";

type ChildProps = {
    onPublishEvent: (type:string,data: string) => void;
    rowData: any;
    setVisible: (data: boolean) => void;
    setLoader: (data: boolean) => void;
  }

export const SchedulesEditForm: FunctionComponent<ChildProps> = ({ rowData, setVisible, setLoader, onPublishEvent }) => {

    const navigate = useNavigate();
    const toast = useRef<Toast>(null);
    const [formvalue, setFormvalue] = useState<{ [key: string]: any }>(EventPayload)
    const beginDatePickerRef = useRef<DatePicker>(null);
    const endDatePickerRef = useRef<DatePicker>(null);
    const scheduleDatePickerRef = useRef<DatePicker>(null);
    const [selectedScheduleDate, setSelectedScheduleDate] = useState<any>(null);
    const [selectedScheduleDateTemp, setSelectedScheduleDateTemp] = useState<any>(null);
    const [selectedBeginDate, setSelectedBeginDate] = useState<any>(null);
    const [selectedBeginDateTemp, setSelectedBeginDateTemp] = useState<any>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
    const [selectedEndDateTemp, setSelectedEndDateTemp] = useState<any>(null);
    const [selectedPayload, setSelectedPayload] = useState<Payload | undefined>();
    
    useEffect(() => {
        if (rowData?.payload) {
            const startTime = new Date(rowData.payload.start_time)
            setSelectedBeginDate(startTime);
            setSelectedBeginDateTemp(startTime);
             
            const endTime = new Date(rowData.payload.end_time);
            setSelectedEndDate(endTime);
            setSelectedEndDateTemp(endTime);
            
            const schTime = new Date(rowData.schedule)
            setSelectedScheduleDate(schTime);
            setSelectedScheduleDateTemp(schTime);
            const initialPayload = signalPayloadValues.find(payload => payload.key === rowData.payload.signal_payload);
            if (initialPayload) {
              setSelectedPayload(initialPayload);
              setFormvalue({ ...formvalue, signalPayload: initialPayload.key });
            }
            
            setFormvalue(prev => ({
                ...prev,
                beginDate: rowData.payload.start_time,
                endDate: rowData.payload.end_time,
                scheduleTime: rowData.schedule,
                target: rowData.payload.ven_id,
                testEvent: rowData.payload.test_event
              }))

        }
    }, [rowData]);
    console.log(formvalue);
    
    
    const handleBeginDateChange = () => {
        setSelectedBeginDate(selectedBeginDateTemp);
        let payload = {...formvalue};
        payload["beginDate"] = selectedBeginDateTemp?.toISOString()
        setFormvalue(payload);
    }

    const handleScheduleDateChange = () => {
        setSelectedScheduleDate(selectedScheduleDateTemp);
        let payload = {...formvalue};
        payload["scheduleTime"] = selectedScheduleDateTemp?.toISOString()
        setFormvalue(payload);
    }

    const handleEndDateChange = () => {
        setSelectedEndDate(selectedEndDateTemp);
        let payload = {...formvalue};
        payload["endDate"] = selectedEndDateTemp?.toISOString();
        setFormvalue(payload);
    }

    const handleFormChange: Function = (e: React.ChangeEvent<HTMLInputElement>) => {
        let payload = {...formvalue};
        payload[e.target.id] = e.target.value;
        setFormvalue(payload);
    }

    const handleBeginDateTextBoxClick = () => {

        if (beginDatePickerRef.current) {
            beginDatePickerRef.current.setOpen(true);
        }

    }

    const handleScheduleDateTextBoxClick = () => {

        if (scheduleDatePickerRef.current) {
            scheduleDatePickerRef.current.setOpen(true);
        }

    }

    const handleEndDateTextBoxClick = () => {
        if (endDatePickerRef.current) {
            endDatePickerRef.current.setOpen(true);
        }
    }
    
    const handleCheckBoxChange: Function = (id: string, e: boolean) => {
        let payload = {...formvalue};
        payload[id] = e ? true : false;
        setFormvalue(payload);
    }

    const handleRadioBoxChange = (value: any) => {
        let payload = { ...formvalue };
        payload["signalPayload"] = value.key;
        setFormvalue(payload);
        setSelectedPayload(value);
      };
    
    const updateEvent: MouseEventHandler<HTMLButtonElement> = async () => {
        try {            
            if (validateEventPayload(formvalue)) {
                setLoader(true);
                let response = await UpdateScheduleService(formvalue, rowData?.id)
                if (response?.status === 200 || response?.status === 201) {
                    setVisible(false)
                    onPublishEvent('Updated',response?.data?.data?.request_id);
                } else if (response?.status === 403) {
                    navigate("/login");
                } else {
                    setLoader(false);
                    showToast(toast,"error", "Failure", "Server returned " + response?.status, false);
                }
            }
        } catch (error) {
            setLoader(false);
            showToast(toast,"error", "Failure", "Unable to update : "+ error, false);
        }
    };


    function validateEventPayload(formvalue: { [key: string]: any; }) {
        const isNull = Object.values(formvalue).some(value => {
            return value === null;
        });
        if (isNull) {
            showToast(toast, "error", "Payload Validation Error", "Please enter all the required input fields", false)
            return false
        } else {
            if (new Date(formvalue['scheduleTime']) > new Date(formvalue['beginDate'])) { 
                showToast(toast, "error", "Please Verify Schedule Date & Time", "Schedule time should be before event start date", false);
                return false; 
            }
            if (new Date(formvalue['beginDate']) > new Date() && new Date(formvalue['endDate']) > new Date(formvalue['beginDate'])) { 
                return true; 
            } else { 
                showToast(toast, "error", "Payload Validation Error", "Please check the calendar inputs", false);
                return false; 
            }
        }
    } 

    const clearForm = () => {
        setFormvalue({
            schedule : null,
            ven_id: null,
            vtn_name: 'FORD_VTN_SIMULATOR',
            start_time: null,
            end_time: null,
            signal_name: 'SIMPLE',
            area_code: '48216',
            signal_payload: '',
            test_event: false,
            duration: 'PT60'
        });
        setSelectedBeginDate(null);
        setSelectedEndDate(null);
        setSelectedBeginDateTemp(null);
        setSelectedEndDateTemp(null);
        setSelectedScheduleDate(null)
    };
    
    return (
    <EStyles.EventsFormContainer className="p-grid p-fluid">
        <Toast ref={toast}/>
       <EStyles.EventsInputContainer>
          <EStyles.RowContainer>
                    <EStyles.LeftColumnContainer className="mt-4">
                            <span>Schedule Date & Time :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                    <EStyles.CheckboxContainer>
                            <Checkbox data-testid="virtual-end-node" inputId="ven" name="ven" disabled
                                      onChange={(e) => handleCheckBoxChange("ven", e.checked)} checked={true}/>
                            <label htmlFor="ven">Virtual End Node</label>
                            </EStyles.CheckboxContainer>
                        <InputText data-testid="begin-date" id="scheduleTime"
                                   value={selectedScheduleDate ? selectedScheduleDate.toLocaleDateString('en-US', {
                                       year: 'numeric',
                                       month: '2-digit',
                                       day: '2-digit',
                                       hour: '2-digit',
                                       minute: '2-digit',
                                       hour12: false
                                   }).replace(',', '') : ''} onClick={handleScheduleDateTextBoxClick}
                        />
                        <EStyles.StyledDatePicker ref={scheduleDatePickerRef} selected={selectedScheduleDateTemp}
                                          onChange={(date: Date) => {
                                              setSelectedScheduleDateTemp(date)
                                          }}
                                          showTimeSelect
                                          onCalendarClose={() => handleScheduleDateChange()}
                                          timeFormat="HH:mm"
                                          timeIntervals={15}
                                          timeCaption="time"
                                          minDate={new Date()}
                                          minTime={new Date(selectedScheduleDateTemp ? new Date().setHours(0, 0) : new Date())}
                                          maxTime={new Date(new Date().setHours(23, 59))}
                                          dateFormat="MM/dd/yyy HH:mm"/>
                                          
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                <EStyles.LeftColumnContainer className="mt-4"><span>Target :</span></EStyles.LeftColumnContainer>
                <EStyles.RightColumnContainer>
                  
                        <Dropdown data-testid="target" id="target"
                                  value={rowData?.payload?.ven_id}
                                  onChange={(e) => handleFormChange(e)}
                                  options={vens} optionLabel="name" optionValue="value"
                                  appendTo={"self"} placeholder="Select Target" className="w-full"/>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                    <EStyles.LeftColumnContainer><span>Event Start :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                        <InputText data-testid="begin-date" id="beginDate"
                                   value={selectedBeginDate? selectedBeginDate.toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                }).replace(',', '') : ''} onClick={handleBeginDateTextBoxClick}
                        />
                        <EStyles.StyledDatePicker ref={beginDatePickerRef} selected={selectedBeginDateTemp}
                                          onChange={(date: Date) => {
                                              setSelectedBeginDateTemp(date)
                                          }}
                                          showTimeSelect
                                          onCalendarClose={() => handleBeginDateChange()}
                                          timeFormat="HH:mm"
                                          timeIntervals={15}
                                          timeCaption="time"
                                          minDate={new Date()}
                                          minTime={new Date(selectedBeginDateTemp ? new Date().setHours(0, 0) : new Date())}
                                          maxTime={new Date(new Date().setHours(23, 59))}
                                          dateFormat="MM/dd/yyy HH:mm"/>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                    <EStyles.LeftColumnContainer><span>Event End :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                        <InputText data-testid="end-date" id="endDate"
                                   value={selectedEndDate? selectedEndDate.toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                }).replace(',', '') : ''} onClick={handleEndDateTextBoxClick}
                        />

                        <EStyles.StyledDatePicker ref={endDatePickerRef} selected={selectedEndDateTemp}
                                          onChange={(date: Date) => {
                                              setSelectedEndDateTemp(date)
                                          }}
                                          onCalendarClose={() => handleEndDateChange()}
                                          showTimeSelect
                                          timeFormat="HH:mm"
                                          timeIntervals={15}
                                          timeCaption="time"
                                          minDate={new Date()}
                                          minTime={new Date(selectedEndDateTemp  ? new Date().setHours(0, 0) : new Date())}
                                          maxTime={new Date(new Date().setHours(23, 59))}
                                          dateFormat="MM/dd/yyy HH:mm"/>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>

                <EStyles.RowContainer>
                    <EStyles.LeftColumnContainer><span>Area :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                        <InputText data-testid="area" id="area" value={rowData.payload.area_code} disabled={true}
                                   onChange={e => handleFormChange(e)}/>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                    <EStyles.LeftColumnContainer><span>Signal Name :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                        <Dropdown data-testid="signal-name" id="signalName" value={formvalue["signalName"]}
                                  onChange={(e) => handleFormChange(e)}
                                  options={signalNames} optionLabel="name" optionValue="value"
                                  placeholder="Select a signal name" className="w-full"/>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                <EStyles.LeftColumnContainer className="mb-6"><span>Signal Payload Value :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                        <EStyles.SignalPayloadContainer>
                            {signalPayloadValues.map((payloadValues) => {
                                return (
                                    <div key={payloadValues.key} className="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2">
                                        <div className="flex align-items-center">
                                            <RadioButton
                                                inputId={payloadValues.name}
                                                name="category"
                                                value={payloadValues}
                                                onChange={(e) => handleRadioBoxChange(e.value)}
                                                checked={selectedPayload ? selectedPayload.key === payloadValues.key : false}
                                            />
                                            <label htmlFor={payloadValues.name} className="ml-2">{payloadValues.name}</label>
                                        </div>
                                    </div>
                                );
                            })}
                        </EStyles.SignalPayloadContainer>
                        <EStyles.CheckboxContainer>
                            <Checkbox data-testid="test-event" inputId="testEvent" name="testEvent"
                                className={"testEvent"}
                                onChange={(e) => handleCheckBoxChange("testEvent", e.target.checked)}
                                checked={formvalue['testEvent'] === true} />
                            <label htmlFor="testEvent">Is a Test Event?</label>
                        </EStyles.CheckboxContainer>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                    <EStyles.EventsContainerButton onClick={clearForm}>Clear</EStyles.EventsContainerButton>
                    <EStyles.EventsContainerButton id="publish" onClick={updateEvent}>Update Schedule</EStyles.EventsContainerButton>                    
                </EStyles.RowContainer>
            </EStyles.EventsInputContainer>
    </EStyles.EventsFormContainer>)    
}


