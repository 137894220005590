import axios from 'axios';
import { bff_url } from '../const/app.constants';

const axiosInstance = axios.create({
    baseURL: bff_url,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    maxBodyLength: Infinity
});

axiosInstance.interceptors.response.use(
    response => {
        const statusCode = response.status
        if(statusCode=== 401 || statusCode === 403){
            window.location.replace('/login')
        }else if(statusCode === 200 || statusCode === 201 || statusCode === 400){
            return response;
        }else{
            window.location.replace('/error')
        }
        return new Promise(()=> (''));
    },
    error => {
        const statusCode = error.response?.status;
        const apiName:string = error.config.apiName + " API" || 'Unknown API';
        console.log(`Network Error on : ${apiName} - ${statusCode}`);
        if(statusCode === 401 || statusCode === 403){
            window.location.replace('/login')
        }else{
            window.location.replace('/error')
        }
        return new Promise(()=> (''))
    }
);

export default axiosInstance;
