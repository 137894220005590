import styled from "styled-components";

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--primary-ui);
    transition: 250ms;
`;

const SidebarLink = styled.div`
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    padding: 3rem 0.2rem;
    margin:5px;
    text-decoration: none;
    color: #ffffff;
    &:hover {
        color: var(--primary-ui);
        background-color: #ffffff;
        border-radius: 10px;    
    }
`;

const SidebarLabel = styled.div`
    display : flex;
    align-items: center;
`;

const SidebarIcon = styled.div<{$hover:boolean;}>`
    display : flex;
    align-items: center;
    padding : 8px;
    fill :${props => props.$hover ? "var(--primary-ui)" : "#000000"};
`;

const SidebarWrap = styled.div`
    margin : 1rem 0rem;
`;

const SidebarLinkWrapper = styled.div`
    display : flex;
    align-items: center;
    justify-content: space-around;
    flex-direction:column; 
`;

export const SStyles = {
    SidebarContainer,
SidebarLink,
SidebarLabel,
SidebarIcon,
SidebarWrap,
SidebarLinkWrapper
}