import React, { useMemo } from 'react';
import { IconContext } from 'react-icons';
import logo from './../../assets/images/logoimage.png';
import { NStyles } from './navbarStyles';

export const Navbar: React.FC<{}> = () => {
    const iconContextValue = useMemo(() => ({size:'2.5rem', color:"var(--primary-ui)"}), []);

    return (
    <IconContext.Provider value={iconContextValue}>
        <NStyles.NavbarContainer>
            <NStyles.LogoContainer>
                <NStyles.Logo src={logo} width={48} height={46} alt='logoimage.png'></NStyles.Logo>
                <NStyles.HeaderText>Portland General Electric</NStyles.HeaderText>
            </NStyles.LogoContainer>
        </NStyles.NavbarContainer>
    </IconContext.Provider>
    )
}
