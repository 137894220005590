import styled from "styled-components";

const NavbarContainer = styled.div`
    width           : 100%;
    flex-grow       : 0;
    display         : flex;
    justify-content : space-between;
    background      : #ffffff   ;
`;

const Logo = styled.img.attrs({className:"scalein animation-duration-200"})`
    align-items: center;
    margin : 0px 0px 0px 10px; 
    display :   flex;
`;

const LogoContainer = styled.div`
    align-items: center;
    padding : 10px 5px;
    display :   flex;
`;

const Logout = styled.div`
    display: flex;
    align-items: center;
    padding :   30px;
`; 
const HeaderText = styled.h1.attrs({className:"scalein animation-duration-200"})`
    margin: 5px;
    font-size: 2em;
    font-weight: bold;
    background: var(--primary-ui);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const NStyles = {
  NavbarContainer,
  Logo,
  LogoContainer,
  Logout,
  HeaderText
};
