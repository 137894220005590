import React, {FunctionComponent, useEffect, useState} from "react";
import {ClipLoader} from "react-spinners";
import {AuthService} from "../../service/authService";

export const Auth: FunctionComponent = () => {
    const [loader, setLoader] = useState<boolean>(false);
    useEffect(() => {
        const getAuthUrl = async () => {
            setLoader(true);
            let response = await AuthService();
            setLoader(false)
            window.location.assign(response.data.url);
        }
        getAuthUrl();
    }, []);
    return <div className="loader">
        <ClipLoader color="var(--primary-ui)" loading={loader} size={100} aria-label="Publishing" data-testid="loader"
                    className="loader-img"/>
    </div>
}

export default Auth;