import axiosInstance from "../Utils/Interceptor";

export const EventScheduleService = async(data:{[key:string]:any}) => {
    return axiosInstance.post('/schedules', data ,{apiName : "Schedule Event"} as object);
};
export const UpdateScheduleService = async(data:{[key:string]:any},id:any) => {
    return axiosInstance.put('/schedules/' + id, data ,{apiName : "Edit Schedule"} as object);
};
export const DeleteScheduleService = async(id:any) => {
    return axiosInstance.delete('/schedules/' + id,{apiName : "Delete Schedule"} as object);
};