import styled from "styled-components";

const CellStyle = styled.td`
  padding: 6px;
  border: 1px solid grey;
`;

const KeyCellStyle = styled.td`
  padding: 6px;
  border: 1px solid grey;
  background-color: lightgrey;
  color: black;
  font-weight: bold;
`;

export const EOverlayStyles = {
  CellStyle,
  KeyCellStyle,
};
