import React from 'react';
import { Navbar } from './components/navbar/navbar';
import { Sidebar } from './components/sidebar/sidebar';
import {Outlet} from "react-router-dom";
import { AppStyles } from './appStyles';

function App() {
  return (
  <AppStyles.AppComponent>
      <Navbar/>
      <AppStyles.MainComponent>
        <Sidebar/>
        <AppStyles.ContentPage>
            <Outlet/>
        </AppStyles.ContentPage>
      </AppStyles.MainComponent>
    </AppStyles.AppComponent>)
}

export default App;
