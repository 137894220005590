import styled from "styled-components";

const PageHeader = styled.span.attrs({
    className: "scalein animation-duration-150",
  })`
    text-align: left;
    // margin: 0px 20px;
    flex-grow: 1;
    color: var(--primary-ui);
    font-weight: bold;
  `;
  const PageHeader2 = styled.div.attrs({
    className: "scalein animation-duration-150 mt-4",
  })`
    text-align: left;
    margin: 10px 20px;
    flex-grow: 1;
    color: var(--primary-ui);
  `;
  const TitleContent = styled.div.attrs({
    className: "scalein animation-duration-150",
  })`
    text-align: left;
    margin: 5px 20px;
    color: var(--primary-ui);
  `;
  
  const CustomButton = styled.button.attrs({
    className: "w-15rem scalein animation-duration-150",
  })`
    background-color: var(--primary-ui);
    color: #fff;
    font-size: 1rem;
    font-weight: 900;
    border-radius: 5px;
    border: 0px;
    height: 50px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `;
  
  const Container2 = styled.div`
  position: fixed;
    bottom: 0;
    right: 0;
    margin: 30px;
    color: var(--primary-ui);
    `
    
const ButtonContainer = styled.div`
    display: flex;
    margin: 15px 25px;
    flex-direction: row;
`;
export const DStyles = {
    PageHeader,
    PageHeader2,
    TitleContent,
    CustomButton,
    Container2,
    ButtonContainer
}