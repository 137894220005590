export const signalPayloadValues = [
    { name: '0', key: 0 },
    { name: '1', key: 1 },
    { name: '2', key: 2 },
    { name: '3', key: 3 }
];

export interface Payload {
    name: string;
    key: number;
}